import React from "react";
import "./Spinner.css";

function Spinner() {
  return (
    <div className="spinner__container">
      <div className="spinner" />
    </div>
  );
}
export default Spinner;
