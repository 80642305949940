import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Spinner from "./components/Spinner/Spinner";

import "./App.css";

//LAZY LOADING
const Home = lazy(() => import("./pages/Home"));
const Faqs = lazy(() => import("./pages/Faqs"));
const Privacy = lazy(() => import("./pages/Privacy"));
const TermsAndConditions = lazy(() => import("./pages/TermsAndConditions"));
const Aml = lazy(() => import("./pages/Aml"));
const NotFound = lazy(() => import("./pages/NotFound"));
const About = lazy(() => import("./pages/About"));

function App() {
  return (
    <div className="App">
      <div id="page-container">
        <Router>
          <div id="content-wrap">
            <Suspense fallback={<Spinner />}>
              <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/faqs" element={<Faqs />} />
                <Route
                  exact
                  path="/termsandconditions"
                  element={<TermsAndConditions />}
                />
                <Route exact path="/privacy" element={<Privacy />} />
                <Route exact path="/aml" element={<Aml />} />
                <Route exact path="/about-us" element={<About />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Suspense>
          </div>
        </Router>
      </div>
    </div>
  );
}

export default App;
