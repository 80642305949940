
import firstThumbnail from "./assets/Img/blog-image-1.png";
import secondThumbnail from "./assets/Img/blog-image.png";
import thirdThumbnail from "./assets/Img/blog-image-2.png";

export const data = [
  {
    page: "Menu",
    links: [
      { label: "Home", url: "./" },
      { label: "Sign In", url: "https://app.getredem.io/signin" },
      { label: "About", url: "./about-us" },
      { label: "Tutorials", url: "./" },
      { label: "Blog", url: "./" },
      { label: "FAQs", url: "./faqs" },
      { label: "Privacy Policy", url: "./privacy" },
      { label: "Terms of Use", url: "./termsandconditions" },
      { label: "AML", url: "./aml" },
    ],
  },
  {
    page: "social",
    links: [
      {
        label: "facebook",
        image:
          "https://res.cloudinary.com/dpqxraalv/image/upload/v1631440934/facebook_qy2dwd.svg",
        url: "https://www.facebook.com/Redem-Technologies-417294715709347/",
        alt: "facebook icon",
      },
      {
        label: "instagram",
        image:
          "https://res.cloudinary.com/dpqxraalv/image/upload/v1631440934/instagram_s6ywo7.svg",
        url: "https://instagram.com/getredem.io?utm_medium=copy_link",
        alt: "instagram icon",
      },

      //   {label: "twitter",
      //   image: "https://res.cloudinary.com/dpqxraalv/image/upload/v1631440934/twitter_aiievq.svg",
      //   url: "./",
      //   alt: "twitter icon"
      // },
      // {
      //   label: "youtube",
      //   image:
      //     "https://res.cloudinary.com/dpqxraalv/image/upload/v1631440934/youtube_1_bmzirv.svg",
      //   url: "https://www.youtube.com/channel/UCjG9_9vFAGSgyBpoHd_qyTg",
      //   alt: "youtube icon",
      // },
    ],
  },
];

export const sublinks = [
  {
    page: "Discover",
    link: [
      {
        image:
          "https://res.cloudinary.com/dpqxraalv/image/upload/v1631391025/Tutorial_Videos_ltubpw.svg",
        title: "How to's",
        text: "All you need to know about trading on Redem",
        url: "/",
      },
      {
        image:
          "https://res.cloudinary.com/dpqxraalv/image/upload/v1631391025/giftbox_z9pbrs.svg",
        title: "Redem goodies",
        text: "Goodies up for grade when you refer, trade etc",
        url: "#",
      },
    ],
  },
];


export const accordionData = [
  {
    title: "Is there a withdrawal fee?",
    content: `Yes, but charges differ.`,
  },
  {
    title: "Why does my trade fail?",
    content: `This happens if your gift card is invalid or if it’s used already.`,
  },
  {
    title: "Does my Redem wallet have a limit? ",
    content: `Your Redem wallet doesn’t have a limit. You can trade whatever amount of gift card you want to.`,
  },
  {
    title: "Adding my bank details for withdrawal?",
    content: `Once you are logged in, tap on your profile icon, click on the option ‘Bank details’ then proceed to fill in the necessary information.`,
  },
  {
    title: "Can my pending withdrawal be cancelled?",
    content: `Your pending withdrawals can only be cancelled when it has been confirmed failed. That way you can choose to change your bank account details.`,
  },
  {
    title: "How to make withdrawals?",
    content: `Click on the ‘Withdraw’ option proceed to choose the bank account(s) you want the money in, enter your desired amount.`,
  },
  {
    title: "How to make transfer? ",
    content: `Click on the ‘Transfer’ option, proceed to put bank details (it doesn’t have to be amongst your saved banks), enter your desired amount.`,
  },
  {
    title: "How does a giftcard get activated?",
    content: `It’s usually activated from the Store where it is bought.`,
  },
  {
    title: "Why is my gift card pending?",
    content: `A gift card trade maybe pending if/when the trade hasn’t been completed. Some gift card trades take minutes to complete while a few others could take longer. `,
  },
  {
    title: "Why can’t I find the card I want to trade?",
    content: `Check the Gift card section to see a list of cards we buy at Redem. `,
  },
  {
    title: "What is E-code?",
    content: `E codes mean electronic codes basically, they are bought Online from websites, and these are codes without the physical gift card from the store.`,
  },
  {
    title: "What are hottest rates?",
    content: `Hottest rates are gift cards with high rates at the moment. `,
  },
  {
    title: "What are today bonuses?",
    content: `Today’s bonuses are goodies, commonly money given to customer when they trade or on special occasion. `,
  },
];

export const giftcardAccordionData = [
  {
    title: "How does a giftcard get activated?",
    content: `It’s usually activated from the Store where it is bought.`,
  },
  {
    title: "Why is my gift card pending?",
    content: `A gift card trade maybe pending if/when the trade hasn’t been completed. Some gift card trades take minutes to complete while a few others could take longer. `,
  },
  {
    title: "Why can’t I find the card I want to trade?",
    content: `Check the Gift card section to see a list of cards we buy at Redem. `,
  },
  {
    title: "What is E-code?",
    content: `E codes mean electronic codes basically, they are bought Online from websites, and these are codes without the physical gift card from the store.`,
  },
  {
    title: "What are hottest rates?",
    content: `Hottest rates are gift cards with high rates at the moment. `,
  },
  {
    title: "What are today bonuses?",
    content: `Today’s bonuses are goodies, commonly money given to customer when they trade or on special occasion. `,
  },
  {
    title: "How do I register?",
    content: `	Download the Redem app, click on the sign up button and fill up the necessary information. A link is then sent to your email, click on the link to validate your account.`,
  },
  {
    title: "How do I reset my pin?",
    content: `	Go your settings and click on ‘change transaction pin’ and OTP will be sent to your mail, verify the OTP then go ahead and change your pin.`,
  },
  {
    title: "What to do when I forget my password?",
    content: `	When this happens, click on the ‘forgot password’, a verification code will be sent to your registered email address, return to your profile to input the code. When that is done you will be given an option to create a new password, proceed to do so.`,
  },
];

export const accountsAccordionData = [
  {
    title: "How do I register?",
    content: `	Download the Redem app, click on the sign up button and fill up the necessary information. A link is then sent to your email, click on the link to validate your account.`,
  },
  {
    title: "How do I reset my pin?",
    content: `	Go your settings and click on ‘change transaction pin’ and OTP will be sent to your mail, verify the OTP then go ahead and change your pin.`,
  },
  {
    title: "What to do when I forget my password?",
    content: `When this happens, click on the ‘forgot password’, a verification code will be sent to your registered email address, return to your profile to input the code. When that is done you will be given an option to create a new password, proceed to do so.`,
  },
];

export const walletAccordionData = [
  {
    title: "Is there a withdrawal fee?",
    content: `Yes, but charges differ.`,
  },
  {
    title: "Why does my trade fail?",
    content: `This happens if your gift card is invalid or if it’s used already.`,
  },
  {
    title: "Does my Redem wallet have a limit? ",
    content: `Your Redem wallet doesn’t have a limit. You can trade whatever amount of gift card you want to.`,
  },
  {
    title: "Adding my bank details for withdrawal?",
    content: `Once you are logged in, tap on your profile icon, click on the option ‘Bank details’ then proceed to fill in the necessary information.`,
  },
  {
    title: "Can my pending withdrawal be cancelled?",
    content: `Your pending withdrawals can only be cancelled when it has been confirmed failed. That way you can choose to change your bank account details.`,
  },
  {
    title: "How to make withdrawals?",
    content: `Click on the ‘Withdraw’ option proceed to choose the bank account(s) you want the money in, enter your desired amount.`,
  },
  {
    title: "How to make transfer? ",
    content: `Click on the ‘Transfer’ option, proceed to put bank details (it doesn’t have to be amongst your saved banks), enter your desired amount.`,
  },
];

export const tutorialData = [
  {
    id: 1,
    image: `${firstThumbnail}`,
    heading: "Beginners can trade their giftcards on Redem",
    subText: "Learn more about how to trade gift cards or ﬁnd out the cards we accept.",
    new: "New"
  },
  {
    id: 2,
    image: `${secondThumbnail}`,
    heading: "Unlock the premium avatars on Redem",
    subText: "Learn more about how to level up and unlock premium avatars available on Redem.",
  },
  {
    id: 3,
    image: `${thirdThumbnail}`,
    heading: "Need fast cash for the weekend? ",
    subText: "Trade with Redem for instant cashout! Your happiness is our priority",
  },
 
];

export const howToData = [
  {
    id: 1,
    heading: "Beginners can trade their giftcards on Redem",
    subText: "Learn more about how to trade gift cards or ﬁnd out the cards we accept.",
    new: "New"
  },
  {
    id: 2,
    heading: "Unlock the premium avatars on Redem",
    subText: "Learn more about how to level up and unlock premium avatars available on Redem.",
  },
  {
    id: 3,
    heading: "Need fast cash for the weekend? ",
    subText: "Trade with Redem for instant cashout! Your happiness is our priority",
  },
 
];

