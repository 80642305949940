import React, { useState, useContext} from "react";
import {sublinks} from "../data";
import useMediaQuery from "./useMediaQuery";
// import { blogData } from "../data";

export const AppContext = React.createContext();


export const AppProvider = ({children}) => {
 

  const [showSideBar, setShowSideBar] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(true);
  const [location, setLocation] = useState({});
  const [page, setPage] = useState({page: "", link: []});
  const [isDarkMode, setIsDarkMode] = useState(false);
  const smallDevice = useMediaQuery("(max-width: 600px)");
  const mediumDevice = useMediaQuery("(max-width: 992px)");
  const largeDevice = useMediaQuery("(max-width: 1400px)");
  // const [blogs, setBlogs] = useState(blogData);
  // const [searchKey, setSearchKey] = useState("");

    // Search submit
  //   const handleSearchBar = e => {
  //     e.preventDefault();
  //     handleSearchResults();
  //   };
  
  //   // Search for blog by category
  //   const handleSearchResults = () => {
  //     const allBlogs = blogData;
  //     const filteredBlogs = allBlogs.filter(blog =>
  //       blog.subHeading.toLowerCase().includes(searchKey.toLowerCase().trim())
  //     );
  //     setBlogs(filteredBlogs);
  // //  console.log(filteredBlogs)
  //   };
  
  //   // Clear search and show all blogs
  //   const handleClearSearch = () => {
  //     setBlogs(blogData);
  //     setSearchKey("");
  //   };

  const openSideBar = () => {
    setShowSideBar(true);
  }

  const closeSideBar = () => {
    setShowSideBar(false);
  }

  const toggleSideBar = () => {
    setShowSideBar(prev => !prev)
  }
  // const onClickButton = () => {
  //    document.getElementById("app--icon--row").style.display = 'block';
  //    console.log('app--icon--row')
  //    }


 
  const openSubMenu = (text, coordinate) => {
    const subPage = sublinks.find(link => link.page === text);
    // console.log(text)
    setPage(subPage);
    setLocation(coordinate);
    setShowSubMenu(true);
  }

  const closeSubMenu = () => {
    setShowSubMenu(false);
  }

  const toggleDarkMode = () => {
    setIsDarkMode(prev => !prev)
  }

  const returnLightMode = () => {
    setIsDarkMode(false)
  } 

  // const appStoreMarket = () => {
  //   window.open("https://apps.apple.com/app/redem/id1616932004");
  // };
 

  // const appleStoreMarket = () => {
  //   window.open("https://play.google.com/store/apps/details?id=com.redem");
  // };

  const scrollUp = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  
  return (
    <AppContext.Provider 
      value={{
        scrollUp,
        // appStoreMarket,
        // appleStoreMarket,
        showSideBar, 
        showSubMenu, 
        // onClickButton,
        // onClick,
        openSideBar, 
        closeSideBar, 
        closeSubMenu,
        toggleSideBar, 
        openSubMenu, 
        location, 
        page, 
        isDarkMode,
        toggleDarkMode,
        returnLightMode,
        smallDevice,
        mediumDevice,
        largeDevice,
        // blogs,
        // handleClearSearch,
        // handleSearchBar,
        // searchKey,
        // setSearchKey
      }}
    >
      {children}
    </AppContext.Provider>
  )
};

const useGlobalContext = () => (
  useContext(AppContext)
);

export default useGlobalContext;


