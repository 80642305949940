import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { AppProvider } from './components/Context';
// import { AppProvider } from './components/Context';
// import {HelmetProvider} from 'react-helmet-async';
// import { Provider } from "react-redux";
// import store from "./js/store/index";


ReactDOM.render(
  <React.StrictMode>
    {/* <HelmetProvider> */}
    <AppProvider>
      {/* <Provider store={store}> */}
        <App />
        {/* </Provider> */}
      </AppProvider>
    {/* </HelmetProvider> */}
    
  </React.StrictMode>,
  document.getElementById('root')
);





